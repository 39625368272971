function Footer(){
    return(
        <footer>
            <div class="fixed-footer"> 
                <p>&#9679; ROJI DESIGN</p>    
            </div>   
        </footer>
    )
}

export default Footer