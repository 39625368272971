function About(){    
    return(
                
    <div className="about">
       <div className='about-pic'>

       </div>
       <div className='paragraph-container'>
            <div className='header'>
                <p>paragraph 1</p>
            </div>
            <div className='paragraph'>
                
            </div>
       </div>
       <div className='paragraph-container'>
            <div className='header'>
                <p>paragraph 2</p>
            </div>
            <div className='paragraph'>

            </div>
       </div>
    </div>
  
    )
}

export default About